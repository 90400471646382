
const keyValue=  "connectInfoEverFork"

const createLocalStorage = (userAddress, connected)=>{

    const connectObject = {
        "userAddress" : userAddress,
        "connected" : connected
    }

    localStorage.setItem(keyValue, JSON.stringify(connectObject))

}

const readLocalStorage = ()=>{
    return localStorage.getItem(keyValue)
}

const clearLocalStorage = ()=>{
    localStorage.removeItem(keyValue)
}

export {createLocalStorage, readLocalStorage, clearLocalStorage}