import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Header from './components/Header';
import Dashboard from './pages/DashBoard';
import AppProvider from './context/main';
import StakingPage from './pages/Staking';

function App() {
  return (
    <div>
      <AppProvider>
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path='/' element={<Dashboard/>} />
      <Route path='/staking' element={<StakingPage/>}  />
    </Routes>
    </BrowserRouter>
    </AppProvider>
    </div>
  );
}

export default App;
