import CardInfo from "../../components/CardInfo"
import styles from './index.module.css'
import { useCallback, useContext, useEffect, useState } from "react"
import {AppContext} from '../../context/main'
import { getUserEverForkBalance } from "../../contractFunctions/everForkToken"
import { getBusdBalance } from "../../contractFunctions/busdToken"
import { claimReward, getTotalDistributed, getUnpaidEarnings } from "../../contractFunctions/EverforkDistributor"
import { BUSDContractAddress, EverForkContractAddress } from "../../contractConstants/main.config"
import ClaimCard from "../../components/ClaimCard"
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = ()=>{

    const [everForkBalance, setEverForkBalance]  =  useState(0)
    const [busdBalance, setBUSDBalance] = useState(0)
    const [totalDistributed, setTotalDistributed] = useState(0)
    const [busdEarnings, setBusdEarnings] = useState(0)

    const {userWalletAddress, isUserConnected} = useContext(AppContext)
    

    const handleGetAccountDetails = useCallback(async()=>{
        const promises =  [getUserEverForkBalance(userWalletAddress), getBusdBalance(userWalletAddress),getUnpaidEarnings(userWalletAddress), getTotalDistributed()]

        const promiseData =  await Promise.all(promises)

        const everForkBalanceRes = promiseData[0]

        const busdBalanceRes = promiseData[1]

        const busdEarningsRes = promiseData[2]

        const totalBusdDistributed = promiseData[3]

        console.log(busdBalanceRes, everForkBalanceRes)
        setEverForkBalance(everForkBalanceRes)
        setBUSDBalance(busdBalanceRes)
        setBusdEarnings(busdEarningsRes)
        setTotalDistributed(totalBusdDistributed)
    }
    ,[userWalletAddress])



    const handleClaimDividend  = async()=>{
       const claimDividendToast =  toast.loading("Claiming Dividend")
        const isDividendClaimed = await claimReward()
        if(isDividendClaimed){
            toast.update(claimDividendToast, {isLoading:false, type:'success', render:"Divdend Claimed Successfully", autoClose:1000})
        }
        else{
            toast.update(claimDividendToast, {isLoading:false, type:'error', render:"An Error Occurred", autoClose:1000})

        }
    }

    useEffect(()=>{
        handleGetAccountDetails()
    },[handleGetAccountDetails])

    return (
        <>
        <ToastContainer position="top-right" />
        <div className={styles.mainPage} >  
        <div className={styles.metricsWrap} >
            <CardInfo metricsLabel={"EverFork Holdings"}  metrics={everForkBalance.toLocaleString("en-US")} />
            <CardInfo metricsLabel={"BUSD Holdings"}  metrics={`$${busdBalance.toLocaleString('en-US')}`} />
            <CardInfo metricsLabel={"Your Total BUSD Earnings"}  metrics={`$${busdEarnings.toLocaleString('en-US')}`}  />
            <CardInfo metricsLabel={"Total BUSD Distributed"}   metrics={`$${totalDistributed.toLocaleString('en-US')}`}   />
            <CardInfo metricsLabel={"EverFork Value"}  />
            {isUserConnected ? 
                        <ClaimCard  handleClaimDividend={()=>handleClaimDividend()} />
: ""
        }
            <br /><br /><br />
            <div className={styles.contractBox} >
                <br />
                <p>EverFork Contract : {EverForkContractAddress} </p>
                <p>BUSD Contract Contract : {BUSDContractAddress} </p>
            </div>

</div>
            
        </div>
        </>
    )
}

export default Dashboard