import { ToastContainer, toast } from 'react-toastify'
import { handleUnstakeToken, handleUnstakeUrgently } from '../../contractFunctions/everForkStaking'
import styles from './index.module.css'

const TokenClaimSection =  ({refreshUserData})=>{


    const handleUnstakeTokens = async()=>{
     const unstakeLoader =  toast.loading(`Unstaking Tokens... `)
  
        try{
            const tokenUnstaked =  await handleUnstakeToken()
            console.log(tokenUnstaked)
            refreshUserData()
            toast.update(unstakeLoader,{render:"Token UnStaked Successfully", isLoading:false, autoClose:1500, type:'success', })


        }catch(error){
            console.log(error)
            toast.update(unstakeLoader,{render:"Something Happened", isLoading:false, autoClose:1500, type:'error', })

        }

    }

    const handleEmergencyUnstake = async()=>{
        const unstakeLoader =  toast.loading(`Unstaking Tokens... `)

        try{
            const tokenUnstaked =  await handleUnstakeUrgently()
            console.log(tokenUnstaked)
            refreshUserData()
            toast.update(unstakeLoader,{render:"Token UnStaked Successfully", isLoading:false, autoClose:1500, type:'success', })


        }catch(error){
            console.log(error)
            toast.update(unstakeLoader,{render:"Something Happened", isLoading:false, autoClose:1500, type:'error', })

        }

    }


    return (
    <>
    <ToastContainer position='top-right' />
    <div className={styles.buttonWrap} >

        <button className={styles.buttonStyle} onClick={()=>handleUnstakeTokens()} >Unstake and Claim rewards</button>

        <button className={styles.buttonStyle} onClick={()=>handleEmergencyUnstake()} >Emergency Unstake (without rewards)</button>


    </div>
    </>
    
    )
}

export default TokenClaimSection