import { useContext, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../context/main'
import { handleStakeToken } from '../../contractFunctions/everForkStaking'
import { approveToken, getTokenApproval } from '../../contractFunctions/everForkToken'
import DepositStaking from '../DepositStaking'
import StakingCalculator from '../StakingCalculator'
import TokenClaimSection from '../TokenClaimSection'
import styles from './index.module.css'


const StakingAction =  ({refreshUserData})=>{

    const {userWalletAddress} = useContext(AppContext)

    const  stakingViews = ["Stake", "Claim", "Calculator"]

    const [currentViewIndex, setCurrentViewIndex] = useState(0)

    const [hasApproved, setHasApproved] = useState(false)

    const handleApprove =  async (amount)=>{
        const approveLoader =  toast.loading(`Approving Staking Contract's ${amount} Transfer `)
        try{
            const tokenAllowance = await getTokenApproval(userWalletAddress)
            console.log(tokenAllowance)
            if (tokenAllowance >= amount){
                toast.update(approveLoader,{render:"Token Approved Successfully", isLoading:false, autoClose:1500, type:'success', })
                setHasApproved(true)

            }else{
                const approved = await approveToken(amount)
            console.log(approved)
            toast.update(approveLoader,{render:"Token Approved Successfully", isLoading:false, autoClose:1500, type:'success', })
            setHasApproved(true)
            }
            
        }
        catch(error){
            console.log(error)
            toast.update(approveLoader,{render:"Something Happened", isLoading:false, autoClose:1500, type:'error', })
            setHasApproved(false)
        }
    }

    const handleStake  = async(amount)=>{

        const stakingLoader =  toast.loading("Staking Token...")
        try{
            const stakingCompleted =  await handleStakeToken(amount)
            toast.update(stakingLoader,{render:`${amount} EverFork Staked Successfully`, isLoading:false, autoClose:1500, type:'success', })
            refreshUserData()
            console.log(stakingCompleted)

        }catch(error){
            toast.update(stakingLoader,{render:error, isLoading:false, autoClose:1500, type:'error', })
            console.log(error)
        }


    }

    return (
        <div className={styles.mainDivWrap} >
            <ToastContainer position='top-right' />
            <div className={styles.stakingMenu} >
                {
                    stakingViews.map((eachView, index)=>{
                        
                        return <div key={index} className={styles.menuSection}  onClick={()=>{
                            setCurrentViewIndex(index)
                        }} >
                            <p>{eachView}</p>
                            <div className={styles.menuBar} style={currentViewIndex === index ? {} : {display:'none'}}  >  </div>
                        </div>
                    })
                }

                
            </div>

            {
                    currentViewIndex === 0 ? <DepositStaking hasApproved={hasApproved} handleApprove={(amount)=>handleApprove(amount)} handleStake={(amount)=>handleStake(amount)}   /> : currentViewIndex === 1 ?
                    
                            <TokenClaimSection refreshUserData={refreshUserData} /> : currentViewIndex === 2 ? <StakingCalculator/> : ""
                }
        </div>
    )

}

export default StakingAction