import styles from './index.module.css'
import Logo from '../../assets/everfork-logo-trans.png'
import { useContext, useEffect, useCallback, useState } from 'react'
import { AppContext } from '../../context/main'
import { ethers } from 'ethers'
import { createLocalStorage, clearLocalStorage, readLocalStorage } from '../../utils/localStorage'
import detectEthereumProvider from '@metamask/detect-provider'
import {AiOutlineClose} from 'react-icons/ai'
import {BiMenuAltRight} from 'react-icons/bi'
import { Link } from 'react-router-dom'


const Header = ()=>{

   

    const { userWalletAddress,
        setUserWalletAddress,
        isUserConnected, 
        setIsUserConnected } = useContext(AppContext)


        const [showMenuMobile, setShowMenuMobile] = useState(false)

        const handleEvents = async ()=>{
            const provider = await detectEthereumProvider()
            console.log(provider)
    
            provider.on("chainChanged", (chainId)=>{
                const parsedChainId = parseInt(chainId)
                console.log(parsedChainId)
            })
    
            provider.on("accountsChanged",(accounts)=>{
                console.log(accounts)
                if(accounts.length  > 0){
                    console.log(accounts)
                    const currentAccount = accounts[0]
                    createLocalStorage(currentAccount, true)
                    setUserWalletAddress(currentAccount)
                    setIsUserConnected(true)
                }else{
                    clearLocalStorage()
                    setIsUserConnected(false)
                    setUserWalletAddress("") 
                }
            })
    
            provider.on('disconnect', (handler)=>{
                console.log(handler)
                console.log('disconnected')
                provider.removeListener('accountsChanged');
                    clearLocalStorage()
                    setIsUserConnected(false)
                    setUserWalletAddress("") 
            })
    }
    
            const handleWalletConnect = useCallback( async ()=>{
    
                if(window.ethereum !== undefined){
                    const provider = new  ethers.providers.Web3Provider(window.ethereum,"any")
                    const signer = provider.getSigner()
                    const chainId = await signer.getChainId()
                    console.log(chainId)
                    if(parseInt(chainId) === 56 ){
                    const allAccounts =  await provider.send("eth_requestAccounts", [])
                    console.log(allAccounts)
                    setUserWalletAddress(allAccounts[0])
                    setIsUserConnected(true)
                    createLocalStorage(allAccounts[0], true)
                    handleEvents()
                    }
                    else{
                        alert("You need to connect to MainNet to use DAPP")
                    }
                }
        
            } ,[])

            const handleWalletDisconnect = ()=>{
                console.log('disconnect')
                clearLocalStorage()
                setIsUserConnected(false)
                setUserWalletAddress("")
            }


        useEffect(()=>{
            const connectDetails = readLocalStorage()
            if(connectDetails != null){
                handleWalletConnect()
            }
        },[handleWalletConnect])


    


    return (
        <>
        <div className={styles.mainHeader} >
            <img src={Logo}  alt="logo"  />
            <div className={styles.navMenu}  >
                <Link style={{textDecoration:'none', color:'white'}} to='/' >
            <p>Dashboard</p>
            </Link>
            <Link style={{textDecoration:'none', color:'white'}} to='/staking'  >
            <p>Staking </p>

            </Link>
            </div>
                <button className={styles.connectButton} onClick={()=>handleWalletConnect()}  > {
                        isUserConnected ? `${userWalletAddress.slice(0,7)}...${userWalletAddress.slice(userWalletAddress.length-4 , userWalletAddress.length) }` : "Connect Wallet"
                    }
               </button> 
               {
                   isUserConnected ? <button className={styles.connectButton} onClick={handleWalletDisconnect}  > Disconnect Wallet
           </button>  : ""
               }
                

               <BiMenuAltRight size={20} color={"white"} onClick={()=>{
                   setShowMenuMobile(true)
               }} 
               className={styles.menuHam}
               />

        </div>

        <div className={ showMenuMobile ? styles.mobileNavBarActive : styles.mobileNavBar  } >
        <AiOutlineClose color='white' size={20} className={styles.closeButton} onClick={()=>{
            setShowMenuMobile(false)
        }} />
            <p onClick={()=>{setShowMenuMobile(false)}} >Dashboard</p>
            <Link style={{textDecoration:'none', color:'white'}} to='/staking'  >
            <p onClick={()=>{setShowMenuMobile(false)}} >Staking </p>
            </Link>
            </div>
        </>
    )

}

export default Header