
import { useContext, useEffect, useState } from 'react'
import StakingAction from '../../components/StakingAction'
import { AppContext } from '../../context/main'
import { getRewardRate, getTotalStaked, getUserReward } from '../../contractFunctions/everForkStaking'
import styles from  './index.module.css'

const StakingPage = ()=>{

    const {userWalletAddress,isUserConnected } =  useContext(AppContext)

    const [totalTokenStaked, setTotalTokensStaked]  = useState(0)

    const [totalRewardsView, setTotalReward] = useState(0)

    const [apyRate, setApyRate] =  useState(0)


    const handlefetchUserData = async()=>{

        if (isUserConnected){
            const promiseArr = [getTotalStaked(userWalletAddress), getUserReward(userWalletAddress), getRewardRate()]

        const promiseResult = await Promise.all(promiseArr)

        console.log(promiseResult)

        const totalAmountStaked =  promiseResult[0]

        setTotalTokensStaked(totalAmountStaked)

        const totalRewards =  promiseResult[1]

        console.log(promiseResult)

        const apy = promiseResult[2]

        setApyRate(apy)


        setTotalReward(totalRewards)
        }

        

    }


    useEffect(()=>{
        handlefetchUserData()
    },[userWalletAddress])

    return (
        <div>
            <div className={styles.mainStakingBox} >
                <br /><br />

                <div className={styles.userStakeDetails} >
                    <div className={styles.stakeDetailBox} >
                        <h4>Everfork Staked</h4>

                        <p>{Number(totalTokenStaked).toLocaleString('en-US')} EverFork </p>
                    </div>
                    <div className={styles.stakeDetailBox} >
                        <h4> Rewards Earned </h4>
                        <p>{totalRewardsView.toLocaleString('en-US')} EverFork</p>
                    </div>

                    <div className={styles.stakeDetailBox} >
                        <h4>APY</h4>
                        <p>{apyRate.toLocaleString('en-US')} % </p>
                    </div>
                </div>
                <br /><br />
                <StakingAction refreshUserData= {handlefetchUserData} />

            </div>
        </div>
    )

}

export default StakingPage

