import {BusdContract} from '../contractConstants/main.config'

const getBusdBalance = (userAddress)=>{
    return new Promise(async(resolve,reject)=>{
        const userBalance =  await BusdContract.balanceOf(userAddress)
        const parseUserBalance =  Number(userBalance.toString()) / Math.pow(10,18)
        resolve(parseUserBalance)
    })
}


export {getBusdBalance}