import styles from './index.module.css'


const ClaimCard = ({handleClaimDividend})=>{

    return <div className={styles.mainCard} >
       <button className={styles.claimButton}  onClick={()=>handleClaimDividend()} >
           Claim Dividends
       </button>
    </div>

}

export default ClaimCard