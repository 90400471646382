import { everForkDistributorContract } from "../contractConstants/main.config"

const getUnpaidEarnings = (userAddress)=>{
    return new Promise(async(resolve,reject)=>{
        const unpaidEarnings = await everForkDistributorContract.getUnpaidEarnings(userAddress)
        const parsedUnpaidEarnings = Number(unpaidEarnings.toString()) / Math.pow(10,18)
        resolve(parsedUnpaidEarnings) 
    })
}

const getTotalDistributed = ()=>{
    return new Promise(async (resolve,reject)=>{
        const TotalDistributed = await everForkDistributorContract.totalDistributed()
        const parsedTotalDistributed = Number(TotalDistributed.toString()) /  Math.pow(10,18)

        resolve(parsedTotalDistributed)
    })
}


const claimReward  = () =>{
    return new Promise(async(resolve,reject)=>{
        try{
            const claimUserRewardReq = await everForkDistributorContract.claimDividend()
            await claimUserRewardReq.wait()
            resolve(true)
        }
        catch(error){
            console.log(error)
            resolve(false)
        }
        
    })
}


export {getUnpaidEarnings, getTotalDistributed, claimReward}

