import { ethers } from "ethers";

import BUSDABI from '../abi/busdABI.json'

import EverForkABI from '../abi/everForkABI.json'

import EverForkDistributor from '../abi/everForkDistributor.json'

import EverForkStakingABI from '../abi/everForkStaking.json'

export const EverForkDistributorContractAddress = "0xE5130269a755C7edA2DF97168daB666DFFedc6f9"

export const EverForkContractAddress = "0xbCf6a7aB11819ad4a5afFb65062Eb03A1C90f0Ac"

export const BUSDContractAddress =  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"

export const EVERFORKTokenStakingContract =  "0x5a6315cB30C35A2D1E62F1E7876Bc02F797dEeCE"

let signer;
if(window.ethereum !== undefined){
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
     signer = provider.getSigner()
}


export const BusdContract = new ethers.Contract(BUSDContractAddress, BUSDABI,signer)
export const everForkTokenContract =  new ethers.Contract(EverForkContractAddress, EverForkABI,signer)
export const everForkDistributorContract =  new ethers.Contract(EverForkDistributorContractAddress, EverForkDistributor, signer)
export const everForkStakingContract  = new ethers.Contract(EVERFORKTokenStakingContract, EverForkStakingABI, signer )
console.log(everForkStakingContract)