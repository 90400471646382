import { createContext, useState } from "react";

export const AppContext =  createContext("")



const AppProvider = ({children})=>{


const [userWalletAddress, setUserWalletAddress] = useState("")
const [isUserConnected, setIsUserConnected] =  useState(false)


    return (
        <AppContext.Provider value={{
            userWalletAddress,
            setUserWalletAddress,
            isUserConnected, 
            setIsUserConnected
        }}>
            {children}
        </AppContext.Provider>
    )

}

export default AppProvider