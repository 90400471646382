import styles from './index.module.css'


const CardInfo = ( { metricsLabel, metrics } )=>{

    return <div className={styles.mainCard} >
        <h3>{metricsLabel}</h3>
        <p>{metrics}</p>
    </div>

}

export default CardInfo