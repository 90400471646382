import styles from './index.module.css'

import { useEffect, useState } from 'react'
import { getInterestRate } from '../../contractFunctions/everForkStaking'
import CardInfo from '../CardInfo'

const StakingCalculator =  ()=>{

    const [interestRate, setInterestRate] = useState(0)
    const [apy, setAPY] =  useState(0)
    const [tokenStaked, setTokenStaked]  = useState(0)
    const [numberOfDaysStaked, setNumberofDaysStaked] = useState(0)
    const [totalReward, setTotalreward] =  useState(0)
    const [rewardsEarned, setRewardsEarned] = useState(0)

    const handleGetInterestRate =  async()=>{
        try {
            const interestRate =  await getInterestRate()
            const rewardRate =  ((1+ ((interestRate.toString() /100) / 365 )) ** 365) * 100
            setAPY(rewardRate)
            setInterestRate(interestRate)
        }
        catch(error){
            console.log(error)
        }

    }


    const calculateReturns = ()=>{
        let  userBalance = Number(tokenStaked)
        for (let i=0; i < numberOfDaysStaked; i++){
            userBalance =  userBalance +  userBalance * interestRate / 100 / 365
        }

        setTotalreward(userBalance)
        setRewardsEarned(userBalance - Number(tokenStaked))
    }

    useEffect(()=>{
        handleGetInterestRate()
    },[])

    return(
    <>
    <br /><br /><br />
    <div className={styles.firstInputFormWrap} >  
    <div className={styles.inputDiv} >
        <p>Tokens Staked</p>
        <input type="text" onChange={(event)=>{
            setTokenStaked(event.target.value)
        }} 
        value={tokenStaked}
        />
    </div>
    <div className={styles.inputDiv}   >
        <p>APY</p>
        <div ><p>{apy.toLocaleString('en-US')} %</p></div>
    </div>
    </div>
    <div className={styles.firstInputFormWrap} >  
    <div className={styles.inputDiv} >
        <p>Number of Days staked</p>
        <input type="text" 
         onChange={(event)=>{
            setNumberofDaysStaked(event.target.value)
        }} 
        value={numberOfDaysStaked}
        />
    </div>
    <div></div>
    <button className={styles.calculateButton}  onClick={calculateReturns} >
        Calculate Returns
    </button>
    </div>

        <br /><br />

        <div className={styles.calculatorResultDiv} >
            <CardInfo metrics={(totalReward).toLocaleString('en-US')} metricsLabel={"Final Balance"} />
            <CardInfo metrics={ rewardsEarned.toLocaleString('en-US')  } metricsLabel={"Total Returns "} />
        </div>

    </>

    )
}

export default StakingCalculator