/* eslint-disable no-undef */
import {everForkTokenContract, EVERFORKTokenStakingContract} from '../contractConstants/main.config'

const getUserEverForkBalance = (userAddress)=>{
    return new Promise(async(resolve,reject)=>{
        const userBalance =  await everForkTokenContract.balanceOf(userAddress)
        const parsedUserBalance = Number(userBalance.toString()) / Math.pow(10,9)
        resolve(parsedUserBalance)
    })
}

const approveToken = (amount)=>{

    return new Promise(async (resolve,reject)=>{
        try{
            const amountParsed = amount * Math.pow(10,9)
            console.log(amountParsed)
            const result =  await everForkTokenContract.approve(EVERFORKTokenStakingContract,BigInt(amountParsed))
            await result.wait()
            resolve(true)
        }
        catch(error){
            console.log(error)
            reject(false)
        }
       
    })
}

const getTokenApproval = (userAddress)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const allowance = await everForkTokenContract.allowance(userAddress, EVERFORKTokenStakingContract)
            const parsedAllowance =  Number(allowance.toString()) /  Math.pow(10,9)
           resolve(parsedAllowance) 
        }
        catch(error){
            console.log(error)
            resolve(0)
        }
    })
}



export {getUserEverForkBalance, approveToken, getTokenApproval}