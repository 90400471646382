/* eslint-disable no-undef */
import { everForkStakingContract } from "../contractConstants/main.config"

const getTotalStaked = (address)=>{
    return new Promise(async (resolve,reject)=>{
        try {
            console.log(everForkStakingContract)
            const totalStaked = await everForkStakingContract.stakingBalance(address)
            resolve(totalStaked.toString())
        }
        catch(error){
            console.log(error)
            reject(error)
        }
       
    })
}

const getRewardRate = ()=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const rate =  await everForkStakingContract.getInterestRate()
            const rewardRate =  ((1+ ((rate.toString() /100) / 365 )) ** 365) * 100
            resolve(rewardRate)
        }
        catch(error){
            console.log(error)
            reject(0)
        }
       
    })
}

const getInterestRate =  ()=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const rate =  await everForkStakingContract.getInterestRate()
            resolve(rate.toString())
        }
        catch(error){
            console.log(error)
            reject(0)
        }
    })
       
}


const getUserReward = (userAddress)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const reward = await everForkStakingContract.calculateUserReturns(userAddress)
            resolve(reward.toString() / Math.pow(10,9))
        }
        catch(error){
            console.log(error)
            reject(error)
        }
        
    })
}

const handleStakeToken = (amount)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const tokenStaked = await everForkStakingContract.stake(BigInt(amount))
            await tokenStaked.wait()
            resolve(true)

        }
        catch(error){
            console.log(error)
            if(error.data !== undefined){
                reject(error.data.message)
            }else{
                resolve("Something Happened")
            }
        }
    })
}

const handleUnstakeToken = ()=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const transaction =  await everForkStakingContract.claimReturns()
            await transaction.wait()
            resolve(true)
        }
        catch(error){
            console.log(error)
            reject(false)
        }
        
    })
}
const handleUnstakeUrgently = ()=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const transaction =  await everForkStakingContract.UnstakeTokenUrgently()
            await transaction.wait()
            resolve(true)
        }
        catch(error){
            console.log(error)
            reject(false)
        }
        
    })
}

export {getTotalStaked, getUserReward, handleStakeToken, handleUnstakeToken, handleUnstakeUrgently, getRewardRate, getInterestRate}