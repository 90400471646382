import { useState } from 'react'
import styles from './index.module.css'

const DepositStaking = ({handleStake,handleApprove, hasApproved})=>{


    const [numberOfCoinsToStake, setNumberOfCoinsToStake] = useState(0)

    return(
        <div className={styles.mainDiv} >
            <div className={styles.topMenu} >
            <div className={styles.inputDiv} >
            <input type="text" className={styles.inputForm} value={numberOfCoinsToStake} placeholder={"Amount"} onChange={(event)=>
            {
                if(isNaN(parseInt(event.target.value)) === false){
                    setNumberOfCoinsToStake(parseInt(event.target.value))
                }else{
                    setNumberOfCoinsToStake(0)
                }
            }
            } />
            </div>
            <div className={styles.actionDiv} >
                {
                    hasApproved ?  <button className={styles.actionButtonFill} onClick={()=>{
                    if(Math.round(numberOfCoinsToStake) <= 0 ){
                        alert("You need to input an amount greater than 0 to stake / approve")
                    }else{
                        handleStake(numberOfCoinsToStake)

                    }
                    
                    }} >Stake</button> : <button className={styles.actionButtonFill} onClick={()=>{
                        if(Math.round(numberOfCoinsToStake) <= 0 ){
                            alert("You need to input an amount greater than 0 to stake / approve")
                        }else{
                            handleApprove(numberOfCoinsToStake)
    
                        }
                    }} >Approve</button>
                }
            
            
            </div>
            </div>
        </div>
    )

}

export default DepositStaking